* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
    line-height: 1.3;
}

main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    display: block;
    width: 80%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login,
.narrow {
    max-width: 300px;
}

.connect,
.medium {
    max-width: 600px;
}

h2,
h3 {
    text-align: center;
}

form h3,
form p {
    margin: 10px;
}

div.center {
    /* display: block;
    width: fit-content;
    margin: 0 auto;
    text-align: center; */
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

p.center {
    text-align: center;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="email"],
input[type="username"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
    background-color: #0056b3;
}

textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    resize: none;
    font: inherit;
}