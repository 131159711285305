* {
  box-sizing: border-box;
}

body {
  background-color: #f2f2f2;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  line-height: 1.3;
  display: flex;
}

main {
  justify-content: center;
  align-items: center;
  display: flex;
}

.box {
  background-color: #fff;
  border-radius: 8px;
  width: 80%;
  padding: 20px;
  display: block;
  box-shadow: 0 0 10px #0000001a;
}

.login, .narrow {
  max-width: 300px;
}

.connect, .medium {
  max-width: 600px;
}

h2, h3 {
  text-align: center;
}

form h3, form p {
  margin: 10px;
}

div.center {
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

p.center {
  text-align: center;
}

label {
  margin-bottom: 5px;
  display: block;
}

input[type="email"], input[type="username"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

input[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: background-color .3s;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

textarea {
  resize: none;
  font: inherit;
  width: 100%;
  height: 100%;
  padding: 10px;
}
/*# sourceMappingURL=choose-username.c4481143.css.map */
